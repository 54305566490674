import { Input, HostListener, OnInit, Component, ChangeDetectionStrategy, ViewChild, ElementRef } from '@angular/core';
import { SessionService } from '../../service/session/session.service';
import { Observable } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
export interface images {
  uuid:string,
  name:string,
  file:string,
  type:string,
}

@Component({
  selector: 'ksf-upload-files',
  templateUrl: './ksf-upload-files.html',
  styleUrls: ['./ksf-upload-files.css']
})

export class KsfUploadFiles implements OnInit {

  @ViewChild("fileGet", {static: false}) fileGet: ElementRef;
  // @Input('stiFile') stiFile: images;
  @Input('multiple') multiple: boolean;
  @Input('uploadCode') uploadCode: string;
  @Input('downloadCode') downloadCode: string;
  @Input('objectUuid') objectUuid: string;
  @Input('listFilesCode') listFilesCode: string;
  @Input('deleteCode') deleteCode: string;
  @Input('title') title: string;

  images:images = {
    uuid:'',
    name:'',
    file:'',
    type:'',
  }

  metadata = {
    value:0,
    mode:'buffer'
  }

  stiFile:images = JSON.parse(JSON.stringify(this.images));
  files:any = [];
  constructor(protected session:SessionService, public snackBar: MatSnackBar) {}

  ngOnInit() {}

  ngOnChanges(changes: ChangeDetectionStrategy) {
    // changes.prop contains the old and the new value...
    if(this.objectUuid != ''){
      // this.getFilesFromDB();
    }
  }
  /**Método para obtener el archivo en base64
  */
  getFile(){
    const fileUpload = this.fileGet.nativeElement;
    fileUpload.onchange = () => {
      // console.log("::::::",fileUpload.files);
      // console.log(fileUpload.files[0]);
     // this.uploadFile(this.fileModel);
     this.stiFile.name = fileUpload.files[0].name;
     this.stiFile.type = fileUpload.files[0].type;
     let file = fileUpload.files[0];
     let reader = new FileReader();
     reader.readAsDataURL(file);
     reader.onload = (event:any) => {
       if(this.stiFile.type == 'image/png' || this.stiFile.type == 'image/jpeg'){
         this.resizeBase64Img(event.target.result,this.stiFile.type).then((compressed:any) => {
            // console.log(compressed);
            this.stiFile.file = compressed;
            //agregamos el file  a el arreglo de files
            let fileAux = JSON.parse(JSON.stringify(this.images));
            fileAux = JSON.parse(JSON.stringify(this.stiFile));
            this.files.push(fileAux);
            this.cleanButonStiFile();
          })
       }else{
         this.stiFile.file = event.target.result;
         //Agregamos el file  a el arreglo de files
         let fileAux = JSON.parse(JSON.stringify(this.images));
         fileAux = JSON.parse(JSON.stringify(this.stiFile));
         this.files.push(fileAux);
         this.cleanButonStiFile();
       }
     };
    };
    fileUpload.click();
  }
  /**función para reducir y optimizar una imagen
  */
  resizeBase64Img(base64,type, width?, height?){
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = base64;
      img.onload = () => {
        let quality = 0.9;
        let reduction = 1;
        let imgWidth = width? width : img.naturalWidth;
        let imgHeight = height? height : img.naturalHeight;
        //calculamos quality y reduccion de iamgen
        if(imgWidth < 2000){
          if(imgWidth < 1500){
            if(imgWidth < 1000){
              if(imgWidth < 500){
                reduction = .80;
              }else{
                reduction = .70;
              }
            }else{
              reduction = .50;
            }
          }else{
            reduction = .30;
          }
        }else{
          reduction = .20
        }
        // console.log('imgWidth: ', imgWidth*reduction);
        // console.log('imgHeight: ', imgHeight*reduction);
        const elem = document.createElement('canvas');
        elem.width = imgWidth*reduction;
        elem.height = imgHeight*reduction;
        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, imgWidth*reduction, imgHeight*reduction);
        const data = ctx.canvas.toDataURL(type,quality);
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }
  /** clean Data
  */
  cleanButonStiFile(){
    this.stiFile = JSON.parse(JSON.stringify(this.images));
  }
}
