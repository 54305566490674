import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from './service/session/authentication.guard';

import { DiputadoComponent } from './diputado/diputado.component';
import { ConocemeComponent } from './conoceme/conoceme.component';
import { EventosComponent } from './eventos/eventos.component';
import { NoticiasComponent } from './noticias/noticias.component';
import { ObrasComponent } from './obras/obras.component';
import { InicioComponent } from './inicio/inicio.component';

import { AdminLoginComponent } from './admin/admin-login/admin-login.component';
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard.component';
import { AdminBannerComponent } from './admin/admin-banner/admin-banner.component';
import { AdminEventComponent } from './admin/admin-event/admin-event.component';
import { AdminNewsComponent } from './admin/admin-news/admin-news.component';
import { AdminWorkComponent } from './admin/admin-work/admin-work.component';

const routes: Routes = [
  { path: 'inicio', component: InicioComponent},
  { path: 'conoceme', component: ConocemeComponent},
  { path: 'eventos/:id', component: EventosComponent},
  { path: 'noticias', component: NoticiasComponent},
  { path: 'obras', component: ObrasComponent},
  { path:'admin', component: AdminLoginComponent  },
  { path:'admin/banner', component: AdminBannerComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/event', component: AdminEventComponent , canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/new', component: AdminNewsComponent, canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },
  { path:'admin/work', component: AdminWorkComponent , canActivate:[AuthenticationGuard], data:{access: ['ADMINISTRATOR']} },

  { path: '**', pathMatch: 'full', redirectTo: 'inicio'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
