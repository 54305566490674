<app-diputado></app-diputado>

<div class="main-wrapper">
    <div class="contenedor" data-aos="fade-down">
        <div>
            <img src="/assets/images/fondo-06.png" class="d-block w-100" alt="">
        </div>
        <div class="container">
            <h1 class="centrado text-center">
                Eventos
            </h1>
        </div>
    </div>

    <section>
        <div class="conten">
            <div class="row">
                <div class="col-sm-8">
                    <div class="card" data-aos="zoom-in">
                        <div class="card-body">
                            <!-- <img [src]="object.image" class="d-block" alt="..."  style="margin: auto; width: 80%;"> -->
                            <div style="margin: auto; width: 80%;" >
                              <ngb-carousel>
                                  <ng-template ngbSlide *ngFor="let object of imagesList; let i = index;">
                                      <div class="picsum-img-wrapper">
                                          <div class="imgSlider">
                                              <img [src]="object.image" class="d-block w-100" alt="...">
                                          </div>
                                      </div>
                                  </ng-template>
                              </ngb-carousel>
                            </div>
                            <div style="margin: auto; width: 80%;">
                              <h2 class="mt-4">{{object.title}}</h2>
                              <p style=" text-align: right; font-size: large; font-weight: bold; margin:0;">{{object.date | date:'dd/MM/yyyy'}}</p>
                              <p style=" text-align: right; font-size: large; font-weight: bold;">{{object.date | date:'HH:mm'}}</p>
                              <p class="mt-2">{{object.resume}}</p>
                              <p>
                                {{object.content}}
                              </p>
                              <div>
                                  <a href="https://twitter.com/DanielGtz_Oax" target="_blank"><img
                                          src="/assets/images/icon-twitter-azul-20.svg" style="width: 2rem;" alt=""></a>
                                  <a href="https://www.facebook.com/DanielGutierrezMX" target="_blank"><img
                                          src="/assets/images/icon-facebook-azul-21.svg" style="width: 2rem;" alt=""></a>
                              </div>
                              <!-- <div style="float: right;">
                                  <button class="btnSiguiente" *ngIf="eventList.length > 1">Siguiente</button>
                              </div> -->
                            </div>

                        </div>
                    </div>
                </div>
                <div class="col-sm-4">
                    <div class="card none">
                        <div class="card-body">
                            <p class="text-center">Últimos eventos</p>
                        <div class="overflow-auto" style="width: 350px;">
                        <div class="list-group">
                            <a class="list-group-item list-group-item-action" *ngFor="let object of eventList" style="cursor:pointer;" (click)="selected(object);">
                                <div class="d-flex w-100 justify-content-between">
                                  <h5 class="mb-1">{{object.title}}</h5>
                                  <h6>{{object.date | date:'dd/MM/yyyy HH:mm'}}</h6>
                                </div>
                                <!-- <p class="mb-1">Some placeholder content in a paragraph.</p> -->
                              </a>

                          </div>
                        </div>

                        </div>
                        <div class="card-body text-center">
                            <p class="text-center">TWITTER</p>
                            <a class="twitter-timeline"data-width="330" data-height="400" href="https://twitter.com/DanielGtz_Oax?ref_src=twsrc%5Etfw">Tweets by DanielGtz_Oax</a>
                        </div>
                        <div class="card-body text-center">
                            <p class="text-center">FACEBOOK</p>
                            <iframe
                                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDanielGutierrezMX&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                width="330" height="300" style="border:none;overflow:hidden" scrolling="no"
                                frameborder="0" allowfullscreen="true"
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div>
<app-contacto></app-contacto>
