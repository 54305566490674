import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SessionService } from '../service/session/session.service';
import * as moment from 'moment';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {
  bannerMin = {
    max: 100,
    page: 1,
  }
  bannerList = [];
  eventsPasMin = {
    max: 100,
    page: 1,
    from:'',
    to:''
  }
  eventsFutMin = {
    max: 100,
    page: 1,
    from:'',
    to:''
  }

  eventsPasList = [];
  eventsFutList = [];

  constructor(private router: Router, public session:SessionService) { }

  ngOnInit(): void {
    this.banners();
    let actualMonth = moment().get('month');
    let endOfTheMonth = moment().set('month',actualMonth+1).set('date', 1).subtract(1, 'day');

    this.eventsPasMin.from =  moment().subtract(11, 'months').format('YYYY-MM-DD');
    this.eventsPasMin.to = moment().format('YYYY-MM-DD');

    this.eventsFutMin.from = moment().format('YYYY-MM-DD');
    this.eventsFutMin.to = moment().add(11, 'months').format('YYYY-MM-DD');

    this.getEventPast();
    this.getEventFut();

  }
  /**funcionalidad para listar los banners
  */
  banners(){
    this.session.postRequestAnonimus("banner:list",this.bannerMin).subscribe((data:any)=>{
      this.bannerList = data.object.instanceList;
    },error=>{
      console.log("banner:list",error);
    })
  }

  /**funcionalidad para obetner los eventos pasados
  */
  getEventPast(){
    this.session.postRequestAnonimus("event:findAllBeetwenDate",this.eventsPasMin).subscribe((data:any)=>{
      this.eventsPasList = data.object.instanceList;
    },error=>{
      console.log("banner:list",error);
    })
  }

  /**funcionalidad para obtener lo eventos futuros
  */
  getEventFut(){
    this.session.postRequestAnonimus("event:findAllBeetwenDate",this.eventsFutMin).subscribe((data:any)=>{
      this.eventsFutList = data.object.instanceList;
    },error=>{
      console.log("banner:list",error);
    })
  }

  Conoceme(): void {
    this.router.navigate(['/conoceme']);
  }

  ConoceObras(): void {
    this.router.navigate(['/obras']);
  }

}
