<section class="contacto" id="contacto">
    <div class="container" data-aos="fade-up">
        <div class="contenido">
            <div>
                <p class="contac">Contacto</p>
            </div>
            <div style="display: inline-block;">
                <img src="/assets/images/icon-phone-15.svg" class="info sociales"  alt="">
                <p class="info">951 128 4955</p>
            </div>
            <div>
                <img src="/assets/images/icon-email-14.svg" class="info sociales"  alt="">
                <p class="info">danielgutierrezoax@gmail.com</p>
            </div>
            <div class="redesSociales">
                <div>
                    <a href="https://twitter.com/DanielGtz_Oax" target="_blank"> <img src="/assets/images/icono-twitter-circulo-16.svg"
                            class="sociales" alt=""></a>
                </div>
                <div>
                    <a href="https://www.facebook.com/DanielGutierrezMX" target="_blank"> <img
                            src="/assets/images/icono-facebook-circulo-17.svg" class="sociales" alt=""></a>
                </div>
                <div>
                    <a href="https://www.youtube.com/channel/UC0srKRr5AwIGg2-MxcOpXGA/featured" target="_blank"> <img src="/assets/images/icono-youtube-circulo-22.svg"
                            class="sociales" alt=""></a>
                </div>
            </div>
        </div>
    </div>
</section>