import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-diputado',
  templateUrl: './diputado.component.html',
  styleUrls: ['./diputado.component.css']
})
export class DiputadoComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

}
