
<div class="row title-modal">
  <div class="col-1">

  </div>
  <div class="col-6">
    <h3>Nuevo Evento</h3>
  </div>
</div>
<div class="row justify-content-center">
  <div class="col-11">
    <div class="row mt-2">
      <div class="col-12">
        <p>Ingresa los datos requeridos del formulario.</p>
      </div>
      <mat-form-field appearance="outline" class="col-12 col-md-8">
        <mat-label>Titulo.</mat-label>
        <input matInput placeholder="Titulo" name="nombre" [formControl]="complexForm.controls['title']" [(ngModel)]="object.title" required>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['title'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-12 col-md-6">
        <mat-label>Fecha</mat-label>
        <input matInput [matDatepicker]="picker" name="date" [formControl]="complexForm.controls['date']" [(ngModel)]="metadata.date" required>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['date'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-3">
        <mat-label>Hr(24hrs).</mat-label>
        <input matInput placeholder="Hr(24hrs)." name="hr" [(ngModel)]="metadata.hr">
      </mat-form-field>
      <mat-form-field appearance="outline" class="col-12 col-md-3">
        <mat-label>Min.</mat-label>
        <input matInput placeholder="Min." name="Min" [(ngModel)]="metadata.min">
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-12">
        <mat-label>Resumen</mat-label>
        <textarea matInput placeholder="Resumen" name="resumen" [formControl]="complexForm.controls['resume']" [(ngModel)]="object.resume" required></textarea>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['resume'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>

      <mat-form-field appearance="outline" class="col-12 col-md-12">
        <mat-label>Contenido</mat-label>
        <textarea matInput placeholder="Contenido" name="content" [formControl]="complexForm.controls['content']" [(ngModel)]="object.content" required></textarea>
        <mat-hint>
          <mat-error *ngIf="complexForm.controls['content'].hasError('required')">
            Campo <strong>requerido</strong>.
          </mat-error>
        </mat-hint>
      </mat-form-field>



      <!-- <div class="col-12">
        <input type="file" #imgGet id="imgGet" name="imgGet" style="display:none;" accept="image/*"/>
        <div class="cardImg">
          <span *ngIf="metadata.img.name == ''">Agregar imagen</span>
          <span *ngIf="metadata.img.name != ''">{{metadata.img.name}}</span>
          <button mat-raised-button color="primary" class="cardImgButtom" (click)="loadImg();" *ngIf="metadata.img.name == '' && !metadata.uploadted">Seleccionar</button>
          <button mat-raised-button color="primary" class="cardImgButtom" (click)="uploadImg();" *ngIf="metadata.img.name != '' && !metadata.uploadted">Subir</button>
          <span class="material-icons cardImgIcon" style="cursor:pointer;" *ngIf="metadata.uploadted" (click)="deleteImg();">delete</span>
          <span class="material-icons cardImgIcon" *ngIf="metadata.uploadted">verified</span>
        </div>
      </div> -->

      <div class="col-12">
        <p>Ingrega las images a utilzar en la plataforma, dale click en <strong>"SUBIR"</strong> y selecciona la <strong>portada principal</strong> de tu evento</p>
        <div *ngFor="let object of imgesList">
          <div class="row">
            <div class="col-1">
              <button mat-icon-button *ngIf="!object.cover" (click)="coverSelected(object);" style="top:25%;"> <mat-icon>radio_button_unchecked</mat-icon></button>
              <button mat-icon-button *ngIf="object.cover"  style="top:25%;"> <mat-icon>check_circle_outline</mat-icon></button>
            </div>
            <div class="col-9">
              <app-image-directive [uploadCode]="'event:upload'" [mFile]="object"></app-image-directive>
            </div>
            <div class="col-1">
              <button mat-icon-button *ngIf="object.uploadted && object.img.name != ''"  style="top:25%;" (click)="deleteAnImage(object)"> <mat-icon>delete</mat-icon></button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mt-2">
        <br>
        <button mat-raised-button color="primary"  class="cardImgButtom" (click)="addImgModel();">Agregar nueva imagen</button>
        <button mat-raised-button color="primary"  class="cardImgButtom" (click)="deleteImgModel();">Quitar una imagen</button>
      </div>



    </div>
    <div class="row justify-content-end mt-4">
      <div class="col-4">
        <button mat-flat-button color="warn" style="width:100%;"  (click)="send(object);">Guardar</button>
      </div>
    </div>
  </div>
</div>
