import { Component, OnInit } from '@angular/core';
import { SessionService } from '../service/session/session.service';
import { LoadingService } from '../directive/loading/loading.service';
import * as moment from 'moment';

@Component({
  selector: 'app-noticias',
  templateUrl: './noticias.component.html',
  styleUrls: ['./noticias.component.css']
})
export class NoticiasComponent implements OnInit {
  workMin = {
    max: 100,
    page: 1,
  }
  workList = [];
  metadata = {
    selected:{
      id:'',
      title:'',
      content:'',
      image:''
    }
  }
  imagesList = [];

  constructor(public session:SessionService, public loadding:LoadingService ) { }

  ngOnInit(): void {
    this.information();
  }

  /**funcionalidad para listar los banners
  */
  information(){
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequestAnonimus("information:list",this.workMin).subscribe((data:any)=>{
      this.workList = data.object.instanceList;
      this.selected(this.workList[0]);
      this.loadding.hide();
    },error=>{
      this.loadding.hide();
      console.log("information:list",error);
    })
  }

  /**funcioanlidad para seleccionar una obra principal
  **/
  selected(object:any){
    this.loadding.show(true,"Espere un momento...");
    setTimeout(()=>{
      object.selected = true;
      this.session.postRequestAnonimus("information:findAllImages",{information:{id:object.id}}).subscribe((data:any)=>{
        this.imagesList = data.object.instanceList;
      },error=>{
        console.log(error);
      });
      this.metadata.selected = JSON.parse(JSON.stringify(object));
      this.loadding.hide();
    }, 1000);
  }

}
