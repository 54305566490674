<app-diputado></app-diputado>

<div class="main-wrapper">
    <div data-aos="fade-down">
        <ngb-carousel>
            <ng-template ngbSlide *ngFor="let object of bannerList; let i = index;">
                <a class="picsum-img-wrapper">
                    <img [src]="object.image" alt="banner{{i}}" width="100%">
                </a>
            </ng-template>
        </ngb-carousel>
    </div>


        <div class="contenedor" data-aos="fade-up">
            <div>
                <img src="/assets/images/fondo-02.png" class="d-block w-100" alt="">
            </div>
            <div>
                <h1 class="centrado">Diputado Federal comprometido por el desarrollo de Oaxaca. Distrito
                    X, Miahuatlán de Porfirio Díaz.</h1>
            </div>
            <div>
                <button class="btn btn-outline btnConoceme centradoBtn " routerLink="/noticias">CONOCEME</button>
            </div>
        </div>

        <section class="noticias" style="padding-top: 30px;">
        <div class="conten">
            <div class="row">
                <div class="col-sm-4">
                    <div class="card none">
                        <div class="card-body text-center"  data-aos="zoom-in-up">
                            <p class="text-center">TWITTER</p>
                            <a class="twitter-timeline" data-width="330" data-height="400"
                                href="https://twitter.com/DanielGtz_Oax?ref_src=twsrc%5Etfw">Tweets by DanielGtz_Oax</a>
                        </div>
                        <div class="card-body text-center"  data-aos="zoom-in-up">
                            <p class="text-center">FACEBOOK</p>
                            <iframe
                                src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDanielGutierrezMX&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                                width="330" height="400" style="border:none;overflow:hidden" scrolling="no"
                                frameborder="0" allowfullscreen="true"
                                allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                        </div>
                    </div>
                </div>
                <div class="col-sm-8">
                    <div class="card" style="padding-top: 40px;">
                        <div class="card-body" data-aos="zoom-in-down">
                            <h3 class="text-center">EVENTOS PASADOS</h3>
                            <ngb-carousel>
                                <ng-template ngbSlide *ngFor="let object of eventsPasList; let i = index;">
                                    <div class="picsum-img-wrapper">
                                        <div class="imgSlider">
                                            <img [src]="object.image" class="d-block w-100" alt="...">
                                        </div>
                                        <div class="pSlider">
                                            <p class="text-center">
                                                {{object.resume}}
                                            </p>
                                            <button class="btnConoceme centradoBtn"
                                                routerLink="/eventos/{{object.id}}">VER MÁS</button>

                                        </div>
                                    </div>
                                </ng-template>
                            </ngb-carousel>

                        </div>
                    </div>
                    <div class="card" style="padding-top: 40px;">
                        <div class="card-body" data-aos="zoom-in-down">
                            <h3 class="text-center">CONSULTA MI AGENDA PÚBLICA</h3>
                            <ngb-carousel>
                                <ng-template ngbSlide *ngFor="let object of eventsFutList; let i = index;">
                                    <div class="picsum-img-wrapper">
                                        <div class="imgSlider">
                                            <img [src]="object.image" class="d-block w-100" alt="...">
                                        </div>
                                        <div class="pSlider">
                                            <p class="text-center">
                                                {{object.resume}}
                                            </p>
                                            <button class="btnConoceme centradoBtn"
                                                routerLink="/eventos/{{object.id}}">VER MÁS</button>

                                        </div>
                                    </div>
                                </ng-template>
                            </ngb-carousel>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="text-center unidos">
            <div data-aos="fade-up">
                <p class="uniP">¡Estamos saldando una deuda histórica con las y los trabajadores! Ahora, <br> el salario
                    mínimo nunca estará por debajo de la inflación.</p>
                <p class="cursiva">#UnidosSaldremosAdelante</p>
            </div>
            <div data-aos="fade-up">
                <a class="btn btnConocerMas" target="_blank"
                    href="https://www.youtube.com/channel/UC0srKRr5AwIGg2-MxcOpXGA/featured">CONOCE MÁS</a>

            </div>
        </div>

        <div class="obra" data-aos="fade-down">
            <div>
                <img src="/assets/images/fondo-03.png" style="width: 100%;" alt="">
            </div>
            <div>
                <h3 class="conoce">¡Conoce las obras que he impulsado!</h3>
            </div>
            <div>
                <img src="/assets/images/obra-1.png" class="imgizqu" alt="">
            </div>
            <div>
                <img src="/assets/images/obra-2.png" class="imgdere d-block" alt="">
            </div>
            <div>
                <button class="btn btnConoceMas centradoBtn" (click)="ConoceObras()">CONOCE MÁS</button>
            </div>
        </div>

    </section>

</div>
<app-contacto></app-contacto>
