import { Component, OnInit } from '@angular/core';
import { SessionService } from './../service/session/session.service';
import { LoadingService } from './../directive/loading/loading.service';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'app-eventos',
  templateUrl: './eventos.component.html',
  styleUrls: ['./eventos.component.css']
})
export class EventosComponent implements OnInit {
  eventModel = {
    id:'',
    title:'',
    resume:'',
    content:'',
    url:'',
    date:'',
    image:'',
    status:true
  }
  eventList = [];
  eventMin = {
    max: 100,
    page: 1,
  }
  object = JSON.parse(JSON.stringify(this.eventModel));
  imagesList = [];


  constructor(private session: SessionService, private router:Router, public activatedRoute:ActivatedRoute, public loadding:LoadingService) { }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe((params: Params)=>{
      if(params['id'] == 'more'){
      }else{
        this.object.id = params['id'] ;
        this.session.postRequestAnonimus("event:get",this.object).subscribe((data:any)=>{
          this.object = JSON.parse(JSON.stringify(data.object));
        },error=>{
          console.log("error:event:get",error);
        })
      }
    },error=>{
      console.log("activatedRoute:error",error);
    })
    this.events();
  }

  /**funcionalidad para listar los banners
  */
  events(){
    this.loadding.show(true,"Espere un momento...");
    this.session.postRequestAnonimus("event:list",this.eventMin).subscribe((data:any)=>{
      this.eventList = data.object.instanceList;
      this.selected(this.eventList[0]);
      this.loadding.hide();
    },error=>{
      this.loadding.hide();
      console.log("event:list",error);
    })
  }

  /**funcioanlidad para seleccionar una obra principal
  **/
  selected(object:any){
    this.loadding.show(true,"Espere un momento...");
    setTimeout(()=>{
      object.selected = true;
      this.session.postRequestAnonimus("event:findAllImages",{event:{id:object.id}}).subscribe((data:any)=>{
        this.imagesList = data.object.instanceList;
      },error=>{
        console.log(error);
      });
      this.object = JSON.parse(JSON.stringify(object));
      this.loadding.hide();
    }, 1000);
  }

}
