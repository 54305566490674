
/**Arreglo que contiene todos los endpoints para acceso a al backend.
* *Code:
* @param code código interno para el manejo en frontEnd
* @param endpoint  endpoint para pedir al backEnd
* *
*/
let urls: Array<{ code: string, endpoint: string }> = [
  {
    code:'auth:login',
    endpoint:"auth/login"
  },{
    code: 'auth:signup',
    endpoint: 'auth/signup'
  },{
    code: "auth:user",
    endpoint: "auth/user"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "auth:logout",
    endpoint: "auth/logout"
  },{
    code: "banner:list",
    endpoint: "banner/list"
  },{
    code: "banner:get",
    endpoint: "banner/get"
  },{
    code: "banner:update",
    endpoint: "banner/update"
  },{
    code: "banner:upload",
    endpoint: "banner/upload"
  },{
    code: "banner:delete",
    endpoint: "banner/delete"
  },{
    code: "event:list",
    endpoint: "event/list"
  },{
    code: "event:get",
    endpoint: "event/get"
  },{
    code: "event:update",
    endpoint: "event/update"
  },{
    code: "event:upload",
    endpoint: "event/upload"
  },{
    code: "event:delete",
    endpoint: "event/delete"
  },{
    code: "information:list",
    endpoint: "information/list"
  },{
    code: "information:get",
    endpoint: "information/get"
  },{
    code: "information:update",
    endpoint: "information/update"
  },{
    code: "information:upload",
    endpoint: "information/upload"
  },{
    code: "information:delete",
    endpoint: "information/delete"
  },{
    code: "work:list",
    endpoint: "work/list"
  },{
    code: "work:get",
    endpoint: "work/get"
  },{
    code: "work:update",
    endpoint: "work/update"
  },{
    code: "work:upload",
    endpoint: "work/upload"
  },{
    code: "work:delete",
    endpoint: "work/delete"
  },{
    code: "event:findAllBeetwenDate",
    endpoint: "event/findAllBeetwenDate"
  },{
    code: "event:addImage",
    endpoint: "event/addImage"
  },{
    code: "event:deleteImage",
    endpoint: "event/deleteImage"
  },{
    code: "event:findAllImages",
    endpoint: "event/findAllImages"
  },{
    code: "information:addImage",
    endpoint: "information/addImage"
  },{
    code: "information:deleteImage",
    endpoint: "information/deleteImage"
  },{
    code: "information:findAllImages",
    endpoint: "information/findAllImages"
  },{
    code: "work:addImage",
    endpoint: "work/addImage"
  },{
    code: "work:deleteImage",
    endpoint: "work/deleteImage"
  },{
    code: "work:findAllImages",
    endpoint: "work/findAllImages"
  }
];

export default urls;
