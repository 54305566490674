import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';

@Injectable()
export class StorageTool {

  constructor(private storage: Storage, ) {
  }

  /****** Utilidades del storage *****/

  /**función para almacenar un key
  */
  saveAKeyOnTheStorage(key:string,object:any){
    return new Promise((resolve,reject)=>{
      this.storage.ready().then(() => {
        this.storage.set(key, object).then(()=>{
          resolve(true);
        }).catch((e)=>{
          reject(e)
        });
       });
    })
  }

  /**función para eliminar un key
  */
  deleteAKeyFromStore(key:string){
    return new Promise((resolve,reject) => {
      this.storage.ready().then(() => {
        this.storage.remove(key);
        resolve(true);
      }).catch((e)=>{
        reject(e);
      });
    });
  }

  /**Función para obtener un key del storage
  */
  getAkeyFromStorage(key:string){
    return new Promise((resolve,reject) => {
      this.storage.ready().then(()=>{
        this.storage.get(key).then((data)=>{
          if(data != undefined && data != null){
            resolve(data);
          }else{
            resolve(false);
          }
        }).catch((e)=>{
          reject(e);
        })
      })
    })
  }

  /**función para limpiar el storage
  */
  cleanStorage(){
    return new Promise((resolve,reject) => {
      this.storage.ready().then(()=>{
        this.storage.clear().then(()=>{
          resolve(true);
        }).catch((e)=>{
          reject(e);
        })
      })
    })
  }

  /**función para eliminar un key del storage
  */
  remove(key:string){
    return new Promise((resolve,reject) => {
      this.storage.remove(key).then(()=>{
        resolve(true);
      }).catch(e=>{
        reject(e);
      })
    })
  }

  /****** Fin utilidades del storage  *****/

}
