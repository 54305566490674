import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SessionService } from '../../../service/session/session.module';
import { LoadingService } from '../../../directive/loading/loading.module';
import { FormControl, Validators, FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Component({
  selector: 'app-admin-work-update',
  templateUrl: './admin-work-update.component.html',
  styleUrls: ['./admin-work-update.component.css']
})
export class AdminWorkUpdateComponent implements OnInit {

  @ViewChild("imgGet", {static: false}) fileGet: ElementRef;
  complexForm: FormGroup;
  workModel = {
    id:'',
    title:'',
    content:'',
    url:'',
    date:'',
    image:'',
    status:true
  }
  metadata = {
    date:'',
    min:'',
    hr:'',
    ingles:false,
    img:{
      file: '',
      type: '',
      name: '',
    },
    uploadted:false
  }

  object = JSON.parse(JSON.stringify(this.workModel));

  imgeModel = {
    img:{
      name:'',
      type:'',
      file:''
    },
    uploadted:false
  }
  imgesList:Array<any> = [];

  constructor(public fb: FormBuilder, private session: SessionService, public loadingService: LoadingService, public snackBar: MatSnackBar, public dialogRef: MatDialogRef<AdminWorkUpdateComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.complexForm = fb.group({
      'title': [null, Validators.compose([Validators.required])],
      'content': [null, Validators.compose([Validators.required])],
      'date': [null, Validators.compose([Validators.required])],
    });
  }

  ngOnInit() {
    if(this.data != 'new') {
      this.loadingService.wait();
       // cargamos los datos de la BD
       this.session.postRequest('work:get',{id:this.data}).subscribe((data:any)=>{
         this.object = JSON.parse(JSON.stringify(data.object));
         this.metadata.img.name = this.object.image.substring(this.object.image.lastIndexOf("/")+1, this.object.image.length);
         this.metadata.uploadted = true;
         this.metadata.date = moment(this.object.date).toISOString();
         this.metadata.hr = moment(this.object.date).format('HH');
         this.metadata.min = moment(this.object.date).format('mm');
         this.loadingService.hide();
         for(let item of this.object.images){
           let aux = JSON.parse(JSON.stringify(this.imgeModel));
           aux.uploadted = true;
           aux.img.name = item.image;
           if(aux.img.name == this.object.image){
             aux.cover = true;
           }
           this.imgesList.push(aux);
         }
         this.addImgModel();
       },
       (error)=>{
         this.loadingService.hide();
         console.log('Error: work:get',error)
       })
    }else{
      this.addImgModel();
    }
  }

  /**
   * funcion para guardar una unidades de medida
   */
  send(object){
    if(object.image != ""){
      this.loadingService.show(true,'Guardando registro...');
      object.date = moment(this.metadata.date).format('YYYY-MM-DD')+' 08:00:00';
      this.session.postRequest("work:update",object).subscribe(
        async (data:any) => {
          this.snackBar.open('El fabricante se guardo correctamente', '', {
            duration: 8000
          });
          object.id = data.object.id;
          //Recorremos las imagenes para asocialas al work
          for(let item of this.imgesList){
            if(item.uploadted)
             await this.saveImages(item);
          }
          this.loadingService.hide();
          this.close(true);
        },
        error => {
          console.log("Error: work:update",error);
          this.loadingService.hide();
        }
      );
    }else{
      this.snackBar.open('Seleccione una imagen de portada', 'Aceptar', {duration: 5000});
    }

  }


  /**función para cargar una imagen
  */
  loadImg(){
    const fileUpload = this.fileGet.nativeElement;
    fileUpload.onchange = () => {
     this.metadata.img.name = fileUpload.files[0].name.replace(/ /g, "_");
     this.metadata.img.type = fileUpload.files[0].type;
     let file = fileUpload.files[0];
     // console.log("FILE",fileUpload.files[0]);
     this.metadata.img.file = file;
     // console.log("FILE",this.metadata.img);
    };
    fileUpload.click();
  }

  /** función para subir una imagen al servidor
  */
  async uploadImg(){
    try{
      this.loadingService.show(true,"Espere un momento...")
      const formData = new FormData();
      formData.append('file', this.metadata.img.file, this.metadata.img.name);
      this.session.uploadFile("work:upload",formData).subscribe(async(data:any)=>{
        this.object.image = "https://danielgutierrez.mx/public/uploads/"+data.object.name;
        this.metadata.uploadted = true;
        this.snackBar.open('La imagen se subio con exito', 'Aceptar', {duration: 5000});
        this.loadingService.hide();
      },error=>{
        console.log("work:upload",error);
        this.loadingService.hide();
      })
    }catch(e){
      console.log(e);
      this.loadingService.hide();
    }

  }

  /**función para quitar una iimagen
  */
  deleteImg(){
    this.metadata.img.file = '';
    this.metadata.img.name = '';
    this.metadata.uploadted = false;
  }

  close(status){
    let object = {
      transaction:'',
      code:'',
      object:{}
    };
    if(status){
      object.transaction = 'ok';
      object.object = this.object;
    }else{
      object.transaction = 'bad';
      object.code = 'work:001';
    }
    this.dialogRef.close(object);
  }

  /**funcionalidad para selccionar una imagen con portada principal
  */
  coverSelected(object){
    console.log(object);
    for(let i = 0; i < this.imgesList.length; i++){
      if(object.img.name != ""){
        if(object.uploadted){
          if(this.imgesList[i].img.name == object.img.name){
            this.imgesList[i].cover = true;
            let url = object.img.name.search("https://danielgutierrez.mx/public/uploads/") == -1 ? 'https://danielgutierrez.mx/public/uploads/':'';
            this.object.image = url+this.imgesList[i].img.name;
          }else{
            this.imgesList[i].cover = false;
          }
        }else{
          this.snackBar.open('Por favor suba la imagen para seleccionarla como portada.', 'Aceptar', {duration: 5000});
        }
      }else{
        this.snackBar.open('Ese campo no tiene un imagen.', 'Aceptar', {duration: 5000});
      }
    }

  }

  saveImages(object){
    return new Promise((resolve,reject)=>{
      let url = object.img.name.search("https://danielgutierrez.mx/public/uploads/") == -1 ? 'https://danielgutierrez.mx/public/uploads/':'';
      let aux ={
        image: url + object.img.name,
        work:{
          id:this.object.id
        }
      }
      this.session.postRequest("work:addImage",aux).subscribe((data:any)=>{
        resolve(true);
      },error=>{
        reject(error);
      })
    });
  }

  /**funcionalidad para agregar un nuevo cascaron de imagen
  */
  addImgModel(){
    this.imgesList.push(JSON.parse(JSON.stringify(this.imgeModel)));
  }

  deleteImgModel(){
    let rm = this.imgesList.pop();
    console.log(rm);
    if(rm.img.name != '' && this.object.id != ''){
      if(rm.cover){
        this.object.image = "";
      }
      let aux ={
        image:rm.img.name,
        work:{
          id:this.object.id
        }
      }
      /*funcion para eliminar una imagen de la bd
      */
      this.session.postRequest("work:deleteImage",aux).subscribe((data:any)=>{

      },error=>{
        console.log(error);
      })
    }

  }
  deleteAnImage(object){
    if(object.img.name != '' && this.object.id != ''){
      if(object.cover){
        this.object.image = "";
      }
      let aux ={
        image:object.img.name,
        work:{
          id:this.object.id
        }
      }
      /*funcion para eliminar una imagen de la bd
      */
      this.session.postRequest("work:deleteImage",aux).subscribe((data:any)=>{
        for(let i = 0; i < this.imgesList.length; i++){
          if(this.imgesList[i].img.name == object.img.name){
            this.imgesList.splice(i,1);
          }
        }
      },error=>{
        console.log(error);
      })
    }else{
      for(let i = 0; i < this.imgesList.length; i++){
        if(this.imgesList[i].img.name == object.img.name){
          this.imgesList.splice(i,1);
        }
      }
    }
  }

}
