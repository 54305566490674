
 <div class="main-wrapper">

<nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top nav">
    <div class="navbar-brand">
        <a href="index.html"><img class="img-fluid" src="/assets/images/nombre-11.svg" alt="Image"
                style="width:8rem;"></a>
    </div>
    <div class="navbar-brand">
        <a href="https://www.facebook.com/DanielGutierrezMX" target="_blank"><img class="img-fluid" src="/assets/images/icon-facebook-12.svg" alt="Image"
                style="width:1.5rem;"></a>
        <a href="https://twitter.com/DanielGtz_Oax" target="_blank"><img class="img-fluid" src="/assets/images/icon-twitter-13.svg" alt="Image"
                style="width:1.8rem;"></a>
                <a href="https://www.youtube.com/channel/UC0srKRr5AwIGg2-MxcOpXGA/featured" target="_blank"><img class="img-fluid" src="/assets/images/icon-youtube-23.svg" alt="Image"
                    style="width:1.8rem;"></a>
    </div>
    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto">
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/inicio">INICIO</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/conoceme">CONOCEME</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/eventos/more">EVENTOS</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/noticias">NOTICIAS</a>
            </li>
            <li class="nav-item" routerLinkActive="active">
                <a class="nav-link" routerLink="/obras">OBRAS</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" href="#contacto">CONTACTO</a>
            </li>
        </ul>
    </div>
</nav>

</div>

