<app-diputado></app-diputado>
<div class="main-wrapper">
    
<div class="contenedor" data-aos="fade-down">
    <div>
        <img src="/assets/images/fondo-04.png" style="width: 100%;" alt="">
    </div>
    <div class="container text-center">
        <p class="centrado">En la actualidad legislatura los Diputados de Morena
            nos hemos <br> caracterizado por avanzar en temas fundamentales para el <br> desarrollo de nuestr país
            y con nuestro Presidente Andrés <br> Manuel López Obrador seguiremos haciendo historia.

        </p>
        <p class="cursiva text-center">
            #MiCompromisoEsOaxaca
        </p>
    </div>
</div>

<section style="padding-top: 30px;">
    <div class="conten">
        <div class="row">
            <div class="col-sm-5">
                <div class="card none">
                    <div class="card-body text-center" data-aos="fade-rignt">
                        <p class="text-center">TWITTER</p>
                        <a class="twitter-timeline" data-width="330" data-height="400"
                            href="https://twitter.com/DanielGtz_Oax?ref_src=twsrc%5Etfw">Tweets by DanielGtz_Oax</a>
                    </div>
                    <div class="card-body text-center" data-aos="fade-rignt">
                        <p class="text-center">FACEBOOK</p>
                        <iframe
                            src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FDanielGutierrezMX&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId"
                            width="330" height="450" style="border:none;overflow:hidden" scrolling="no" frameborder="0"
                            allowfullscreen="true"
                            allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"></iframe>
                    </div>
                </div>
            </div>
            <div class="col-sm-7">
                <div class="card" data-aos="zoom-in">
                    <div class="card-body text-center">
                        <img src="/assets/images/Daniel-01.png" style="width: 60%;" alt="">
                    </div>
                    <div class="text-center">
                        <!-- <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Iure repellendus illum optio debitis
                            assumenda accusamus molestias voluptatum accusantium cumque eius perspiciatis magnam atque
                            dolores voluptatem libero, aspernatur, tempore inventore repellat.
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde soluta odit saepe voluptatum
                            repellat sunt maxime laborum voluptas quam, eius voluptatibus maiores excepturi, adipisci
                            officia voluptatem. Quis ipsum necessitatibus assumenda!
                            Lorem, ipsum dolor sit amet consectetur adipisicing elit. Itaque adipisci hic enim
                            voluptates
                            corporis alias corrupti unde, odit ab, debitis ea mollitia, labore eligendi sunt suscipit
                            commodi voluptatem neque aliquam?
                            Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit veniam hic obcaecati libero
                            nihil repellat laudantium nulla molestiae, odit facilis, quisquam perferendis, nesciunt
                            quasi
                            sit. Repudiandae esse voluptates recusandae maiores?
                            Lorem ipsum dolor sit amet consectetur, adipisicing elit. Labore animi, sint temporibus
                            neque
                            beatae molestias numquam accusantium fugit reiciendis ad nostrum saepe dolorem harum, ea
                            maxime
                            eaque amet debitis sed?
                        </p> -->
                    </div>
                    <div>
                        <h5>TRAYECTORIA ADMINISTRATIVA</h5>
                        <P>2016 Secretario del Trabajo de Oaxaca.</P>
                        <h5>TRAYECTORIA POLÍTICA</h5>
                        <P>Presidente del Consejo para Pueblos de Oaxaca (CDEPO).</P>
                        <h5>TRAYECTORIA ACADÉMICA</h5>
                        <P>Licenciatura en Contaduría Pública por la Universidad Autónoma Benito Juárez de Oaxaca.</P>
                        <h5>OTROS RUBROS</h5>
                        <P>Miembro fundador de la Coordinadora de Organizaciones Sociales del Sur-Sureste (COSSSUR).</P>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
</div>
<app-contacto></app-contacto>
