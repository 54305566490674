<div class="row p-2 m-0" [ngClass]="{'menuFixed' : metadata.menuFixed}" #menu style="height: 12vh; background: white;">
  <div class="col-2 logo" style="height: 100%;" routerLink="/admin/dashboard">
    <img src="assets/images/nombre-11.svg" style="height: 100%; padding: 5px;">
  </div>
  <div class="col-10 justify-content-end" style="padding: 0px; padding-top: 2vh;">
    <ul class="font-platino menu" data-animation="center">
      <li class="horizontal"><a routerLink="/admin/event">EVENTOS</a></li>
      <li class="horizontal"><a routerLink="/admin/new">NOTICIAS</a></li>
      <li class="horizontal"><a routerLink="/admin/work">OBRAS</a></li>
      <li class="horizontal"><a routerLink="/admin/banner">BANNER</a></li>
      <li class="horizontal"><a (click)="logout();">SALIR</a></li>
    </ul>
  </div>
</div>
